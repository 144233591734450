var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "register-page" },
    [
      _c(
        "el-container",
        { staticStyle: { height: "100vh" } },
        [
          _c("el-header", { staticClass: "main-header" }),
          _c(
            "el-main",
            [
              _c("div", { staticClass: "main-title" }, [
                _vm._v("技术服务机构账号注册")
              ]),
              _c("div", { staticClass: "register-step unable-selected" }, [
                _c("div", { staticClass: "register-step-item is-complete" }, [
                  _c(
                    "div",
                    {
                      staticClass: "register-step-item-icon",
                      class:
                        _vm.step === 1
                          ? "is-complete-icon"
                          : _vm.step > 1
                          ? "is-complete-icon-check"
                          : ""
                    },
                    [_vm._v("1")]
                  ),
                  _c("span", [_vm._v("单位信息")]),
                  _c("div", { staticClass: "register-step-item-separator" })
                ]),
                _c(
                  "div",
                  {
                    staticClass: "register-step-item",
                    class: _vm.step !== 1 ? "is-complete" : ""
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "register-step-item-icon",
                        class:
                          _vm.step === 2
                            ? "is-complete-icon"
                            : _vm.step > 2
                            ? "is-complete-icon-check"
                            : ""
                      },
                      [_vm._v("2")]
                    ),
                    _c("span", [_vm._v("账号信息")]),
                    _c("div", { staticClass: "register-step-item-separator" })
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "register-step-item",
                    class: _vm.step === 3 ? "is-complete" : ""
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "register-step-item-icon",
                        class: _vm.step === 3 ? "is-complete-icon" : ""
                      },
                      [_vm._v("3")]
                    ),
                    _c("span", [_vm._v("完成注册")])
                  ]
                )
              ]),
              _c(
                "el-form",
                {
                  key: "mainForm",
                  ref: "mainForm",
                  staticClass: "zwx-form edit-form",
                  attrs: {
                    model: _vm.mainForm,
                    rules: !_vm.mainForm.organType ? _vm.rules : _vm.rules1
                  },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.step === 1,
                          expression: "step === 1"
                        }
                      ],
                      staticClass: "employer-content"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: { width: "526px", "text-align": "left" }
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                "label-width": "110px",
                                label: "机构类型：",
                                prop: "organType"
                              }
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  staticClass: "zwx-radio-group",
                                  model: {
                                    value: _vm.mainForm.organType,
                                    callback: function($$v) {
                                      _vm.$set(_vm.mainForm, "organType", $$v)
                                    },
                                    expression: "mainForm.organType"
                                  }
                                },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      staticClass: "zwx-radio",
                                      attrs: { label: false }
                                    },
                                    [_vm._v("第三方服务机构")]
                                  ),
                                  _c(
                                    "el-radio",
                                    {
                                      staticClass: "zwx-radio",
                                      attrs: { label: true }
                                    },
                                    [_vm._v(_vm._s(_vm.helper))]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                color: "#f56c6c",
                                "margin-left": "110px",
                                "margin-top": "-20px",
                                "margin-bottom": "20px",
                                "font-size": "12px"
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  !_vm.mainForm.organType
                                    ? "协助企业开展体检、检测工作"
                                    : "给企业提供全方位的职业健康服务指导"
                                )
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      !_vm.mainForm.organType
                        ? [
                            _c("div", [
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        "label-width": "110px",
                                        label: "机构名称：",
                                        prop: "unitName"
                                      }
                                    },
                                    [
                                      _c("unit-select", {
                                        ref: "unitSelect",
                                        attrs: {
                                          searchUnitName: "unitNameLike",
                                          isPost: true,
                                          isSms4: true,
                                          otherRequestUrl:
                                            "/data/exchange/getUnitListFromEnterprise-0",
                                          width: "416px",
                                          placeholderText: "请输入5个字符搜索",
                                          requestUrl: _vm.requestUrl,
                                          ifAuthority: true
                                        },
                                        on: {
                                          unitNameSearchOrDel: _vm.unitNameDel,
                                          change: _vm.unitNameSelect,
                                          loading: data => _vm.useloading(data)
                                        },
                                        model: {
                                          value: _vm.mainForm.unitName,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.mainForm,
                                              "unitName",
                                              $$v
                                            )
                                          },
                                          expression: "mainForm.unitName"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        "label-width": "110px",
                                        label: "社会信用代码：",
                                        prop: "creditCode"
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: {
                                          width: "416px !important"
                                        },
                                        attrs: {
                                          placeholder: "请输入社会信用代码",
                                          clearable: "",
                                          disabled: _vm.creditCodeDisabled
                                        },
                                        model: {
                                          value: _vm.mainForm.creditCode,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.mainForm,
                                              "creditCode",
                                              $$v
                                            )
                                          },
                                          expression: "mainForm.creditCode"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        "label-width": "110px",
                                        label: "经营地区：",
                                        prop: "zoneCode"
                                      }
                                    },
                                    [
                                      _c("zwx-select-area", {
                                        ref: "bizZoneArea",
                                        staticClass:
                                          "cascader-area-biz-zone select-component",
                                        attrs: {
                                          placeholder: "请选择",
                                          zoneCode12From: _vm.zoneCode12From,
                                          zoneTypeMax: "0",
                                          zoneTypeMin: "4",
                                          showFullName: true
                                        },
                                        on: { change: _vm.workPlaceChange }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        "label-width": "110px",
                                        label: "经营地址：",
                                        prop: "workAddr"
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: {
                                          width: "416px !important"
                                        },
                                        attrs: {
                                          placeholder: "请输入",
                                          maxlength: "400",
                                          clearable: ""
                                        },
                                        model: {
                                          value: _vm.mainForm.workAddr,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.mainForm,
                                              "workAddr",
                                              $$v
                                            )
                                          },
                                          expression: "mainForm.workAddr"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        "label-width": "110px",
                                        label: "联系人：",
                                        prop: "name"
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: {
                                          width: "416px !important"
                                        },
                                        attrs: {
                                          placeholder: "请输入",
                                          maxlength: "100",
                                          clearable: ""
                                        },
                                        model: {
                                          value: _vm.mainForm.name,
                                          callback: function($$v) {
                                            _vm.$set(_vm.mainForm, "name", $$v)
                                          },
                                          expression: "mainForm.name"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        "label-width": "110px",
                                        label: "联系人电话：",
                                        prop: "mobileTel"
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: {
                                          width: "416px !important"
                                        },
                                        attrs: {
                                          placeholder: "请输入",
                                          maxlength: "11",
                                          clearable: ""
                                        },
                                        model: {
                                          value: _vm.mainForm.mobileTel,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.mainForm,
                                              "mobileTel",
                                              $$v
                                            )
                                          },
                                          expression: "mainForm.mobileTel"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticStyle: { width: "524px" } },
                                [
                                  _c("attachmen-upload", {
                                    attrs: {
                                      labelwidth: "110px",
                                      folder: "repository/technical",
                                      label: "备案信息：",
                                      accept:
                                        ".png,.PNG,.jpg,.JPG,.JPEG,.jpeg,.gif,.pdf,.PDF",
                                      limit: 5,
                                      fileProp: "annexList1"
                                    },
                                    model: {
                                      value: _vm.mainForm.annexList1,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.mainForm,
                                          "annexList1",
                                          $$v
                                        )
                                      },
                                      expression: "mainForm.annexList1"
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          ]
                        : [
                            _c("div", [
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        "label-width": "110px",
                                        label: "单位名称：",
                                        prop: "unitName"
                                      }
                                    },
                                    [
                                      _c("unit-select", {
                                        ref: "unitSelect",
                                        attrs: {
                                          width: "416px",
                                          searchUnitName: "unitNameLike",
                                          isPost: true,
                                          isSms4: true,
                                          otherRequestUrl:
                                            "/data/exchange/getUnitListFromEnterprise-0",
                                          placeholderText: "请输入5个字符搜索",
                                          requestUrl: _vm.requestUrl,
                                          ifAuthority: true
                                        },
                                        on: {
                                          unitNameSearchOrDel: _vm.unitNameDel,
                                          change: _vm.unitNameSelect,
                                          loading: data => _vm.useloading(data)
                                        },
                                        model: {
                                          value: _vm.mainForm.unitName,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.mainForm,
                                              "unitName",
                                              $$v
                                            )
                                          },
                                          expression: "mainForm.unitName"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        "label-width": "110px",
                                        label: "社会信用代码：",
                                        prop: "creditCode"
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: {
                                          width: "416px !important"
                                        },
                                        attrs: {
                                          placeholder: "请输入社会信用代码",
                                          clearable: "",
                                          disabled: _vm.creditCodeDisabled
                                        },
                                        model: {
                                          value: _vm.mainForm.creditCode,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.mainForm,
                                              "creditCode",
                                              $$v
                                            )
                                          },
                                          expression: "mainForm.creditCode"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        "label-width": "110px",
                                        label: "经营地区：",
                                        prop: "zoneCode"
                                      }
                                    },
                                    [
                                      _c("zwx-select-area", {
                                        ref: "bizZoneArea",
                                        staticClass:
                                          "cascader-area-biz-zone select-component",
                                        attrs: {
                                          placeholder: "请选择",
                                          zoneCode12From: _vm.zoneCode12From,
                                          zoneTypeMax: "0",
                                          zoneTypeMin: "4",
                                          showFullName: true
                                        },
                                        on: { change: _vm.workPlaceChange }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        "label-width": "110px",
                                        label: "经营地址：",
                                        prop: "workAddr"
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: {
                                          width: "416px !important"
                                        },
                                        attrs: {
                                          placeholder: "请输入",
                                          maxlength: "400",
                                          clearable: ""
                                        },
                                        model: {
                                          value: _vm.mainForm.workAddr,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.mainForm,
                                              "workAddr",
                                              $$v
                                            )
                                          },
                                          expression: "mainForm.workAddr"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        "label-width": "110px",
                                        label: "联系人：",
                                        prop: "name"
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: {
                                          width: "416px !important"
                                        },
                                        attrs: {
                                          placeholder: "请输入",
                                          maxlength: "100",
                                          clearable: ""
                                        },
                                        model: {
                                          value: _vm.mainForm.name,
                                          callback: function($$v) {
                                            _vm.$set(_vm.mainForm, "name", $$v)
                                          },
                                          expression: "mainForm.name"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        "label-width": "110px",
                                        label: "联系人电话：",
                                        prop: "mobileTel"
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: {
                                          width: "416px !important"
                                        },
                                        attrs: {
                                          placeholder: "请输入",
                                          maxlength: "11",
                                          clearable: ""
                                        },
                                        model: {
                                          value: _vm.mainForm.mobileTel,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.mainForm,
                                              "mobileTel",
                                              $$v
                                            )
                                          },
                                          expression: "mainForm.mobileTel"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ])
                          ],
                      _c(
                        "div",
                        {
                          staticStyle: { width: "526px", "text-align": "right" }
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "zwx-button prev-button",
                              on: { click: _vm.back }
                            },
                            [_vm._v("上一步")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "zwx-button next-button",
                              attrs: { type: "primary" },
                              on: { click: _vm.nextStep }
                            },
                            [_vm._v(" 下一步 ")]
                          )
                        ],
                        1
                      )
                    ],
                    2
                  ),
                  _vm.step === 2
                    ? _c("div", { staticClass: "employer-content" }, [
                        _vm.mainForm.registerType == 1
                          ? _c(
                              "div",
                              { staticClass: "ms-name" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      "label-width": "110px",
                                      label: "姓名：",
                                      prop: "user"
                                    }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "zwx-select2",
                                        staticStyle: {
                                          width: "416px !important"
                                        },
                                        attrs: {
                                          placeholder: "请选择",
                                          clearable: ""
                                        },
                                        on: { change: _vm.changeUserInfo },
                                        model: {
                                          value: _vm.mainForm.user,
                                          callback: function($$v) {
                                            _vm.$set(_vm.mainForm, "user", $$v)
                                          },
                                          expression: "mainForm.user"
                                        }
                                      },
                                      _vm._l(_vm.userInfoList, function(item) {
                                        return _c(
                                          "el-option",
                                          {
                                            key: item.uid,
                                            attrs: {
                                              label: item.userName,
                                              value: item.uid
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "ms-options" },
                                              [
                                                _c("span", [
                                                  _vm._v(_vm._s(item.userName))
                                                ]),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.phoneHide(
                                                        _vm.$zwxSm.sm4JsDecrypt(
                                                          item.mobileTel
                                                        )
                                                      )
                                                    )
                                                  )
                                                ])
                                              ]
                                            )
                                          ]
                                        )
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _c("div", [
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        "label-width": "110px",
                                        label: "登录账号：",
                                        prop: "userNo"
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: {
                                          width: "416px !important"
                                        },
                                        attrs: {
                                          placeholder: "请输入",
                                          maxlength: "11",
                                          clearable: ""
                                        },
                                        model: {
                                          value: _vm.mainForm.userNo,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.mainForm,
                                              "userNo",
                                              $$v
                                            )
                                          },
                                          expression: "mainForm.userNo"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        "label-width": "110px",
                                        label: "登录密码：",
                                        prop: "password"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-input",
                                        {
                                          staticStyle: {
                                            width: "416px !important"
                                          },
                                          attrs: {
                                            type: _vm.newView
                                              ? "password"
                                              : null,
                                            placeholder: "请输入",
                                            maxlength: "16"
                                          },
                                          model: {
                                            value: _vm.mainForm.password,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.mainForm,
                                                "password",
                                                $$v
                                              )
                                            },
                                            expression: "mainForm.password"
                                          }
                                        },
                                        [
                                          _c("i", {
                                            class: _vm.newView
                                              ? "el-input__icon  outline chis-icon-outline-eye"
                                              : "el-input__icon outline chis-icon-outline-eye-invisible",
                                            attrs: { slot: "suffix" },
                                            on: {
                                              click: function($event) {
                                                return _vm.passSeeNew()
                                              }
                                            },
                                            slot: "suffix"
                                          })
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.mainForm.password !== "",
                                              expression:
                                                "mainForm.password !== ''"
                                            }
                                          ],
                                          staticClass:
                                            "password-level unable-selected",
                                          class:
                                            _vm.mainForm.passwordLevel === 3
                                              ? "higher"
                                              : _vm.mainForm.passwordLevel === 2
                                              ? "middle"
                                              : "lower"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "password-level-box"
                                            },
                                            [_c("div"), _c("div"), _c("div")]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "password-level-text"
                                            },
                                            [_vm._v("安全等级：")]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "password-level-code"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.mainForm
                                                      .passwordLevel === 3
                                                      ? "高"
                                                      : _vm.mainForm
                                                          .passwordLevel === 2
                                                      ? "中"
                                                      : "低"
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        "label-width": "110px",
                                        label: "确认密码：",
                                        prop: "confirmPassword"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-input",
                                        {
                                          staticStyle: {
                                            width: "416px !important"
                                          },
                                          attrs: {
                                            placeholder: "请输入",
                                            maxlength: "20",
                                            type: _vm.confirmView
                                              ? "password"
                                              : null
                                          },
                                          model: {
                                            value: _vm.mainForm.confirmPassword,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.mainForm,
                                                "confirmPassword",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "mainForm.confirmPassword"
                                          }
                                        },
                                        [
                                          _c("i", {
                                            class: _vm.confirmView
                                              ? "el-input__icon  outline chis-icon-outline-eye"
                                              : "el-input__icon outline chis-icon-outline-eye-invisible",
                                            attrs: { slot: "suffix" },
                                            on: {
                                              click: function($event) {
                                                return _vm.passSeeConfirm()
                                              }
                                            },
                                            slot: "suffix"
                                          })
                                        ]
                                      ),
                                      _c("div", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.$zwxBase.verifyIsNotBlank(
                                                _vm.mainForm.password
                                              ) &&
                                              _vm.$zwxBase.verifyIsNotBlank(
                                                _vm.mainForm.confirmPassword
                                              ) &&
                                              _vm.mainForm.password ==
                                                _vm.mainForm.confirmPassword,
                                            expression:
                                              "$zwxBase.verifyIsNotBlank(mainForm.password) && $zwxBase.verifyIsNotBlank(mainForm.confirmPassword) && mainForm.password == mainForm.confirmPassword"
                                          }
                                        ],
                                        staticClass: "confirmPassword-pass",
                                        class:
                                          _vm.$zwxBase.verifyIsNotBlank(
                                            _vm.mainForm.password
                                          ) &&
                                          _vm.$zwxBase.verifyIsNotBlank(
                                            _vm.mainForm.confirmPassword
                                          ) &&
                                          _vm.mainForm.password ==
                                            _vm.mainForm.confirmPassword
                                            ? "filled chis-icon-filled-completed"
                                            : "",
                                        staticStyle: { color: "#10C77B" }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]),
                        _c(
                          "div",
                          { staticStyle: { "padding-left": "110px" } },
                          [
                            _c("image-verification", {
                              ref: "loginVerification",
                              attrs: { uid: _vm.uid, width: "415px" },
                              on: {
                                success:
                                  _vm.msEnterpriseLoginSuccessVerification
                              }
                            }),
                            _c("div", { staticClass: "warningtext" }, [
                              _vm._v(_vm._s(_vm.tip))
                            ])
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticStyle: { "margin-top": "15px" } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  "label-width": "110px",
                                  label: "短信验证码：",
                                  prop: "verifyCode"
                                }
                              },
                              [
                                _c(
                                  "el-input",
                                  {
                                    staticStyle: { width: "416px" },
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入短信验证码"
                                    },
                                    model: {
                                      value: _vm.mainForm.verifyCode,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.mainForm,
                                          "verifyCode",
                                          $$v
                                        )
                                      },
                                      expression: "mainForm.verifyCode"
                                    }
                                  },
                                  [
                                    _c("template", { slot: "append" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "msg-button",
                                          style:
                                            _vm.showtime !== null
                                              ? "pointer-events: none"
                                              : "cursor: pointer;",
                                          on: { click: _vm.send }
                                        },
                                        [
                                          _vm.showtime === null
                                            ? _c(
                                                "span",
                                                {
                                                  style: {
                                                    color: _vm.isSucess
                                                      ? "#0a4dea !important"
                                                      : "#909399"
                                                  }
                                                },
                                                [_vm._v(" 发送 ")]
                                              )
                                            : _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "#0a4dea!important"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.showtime) +
                                                      " "
                                                  )
                                                ]
                                              )
                                        ]
                                      )
                                    ])
                                  ],
                                  2
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "footer-button" },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "zwx-button prev-button",
                                on: { click: _vm.prevStep }
                              },
                              [_vm._v("上一步")]
                            ),
                            _c(
                              "el-button",
                              {
                                staticClass: "zwx-button next-button",
                                attrs: { type: "primary" },
                                on: { click: _vm.saveRegister }
                              },
                              [_vm._v("确认")]
                            )
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm.step === 3
                    ? _c("div", { staticClass: "account-content" }, [
                        _c("div", { staticClass: "register-success" }),
                        _c("div", { staticClass: "register-success-title" }, [
                          _vm._v("你已成功注册")
                        ]),
                        _c(
                          "div",
                          { staticStyle: { "margin-top": "38px" } },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "zwx-button next-success",
                                attrs: { type: "primary" },
                                on: { click: _vm.toLogin }
                              },
                              [_vm._v("去登录")]
                            )
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                ]
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "bottom",
              class: { bottom2: _vm.step == 2 || _vm.step == 3 }
            },
            [
              _c("div", { staticClass: "bottom-text" }, [
                _vm._v(
                  "Copyright © " +
                    _vm._s(_vm.domainNumber) +
                    " | " +
                    _vm._s(_vm.technicalSupport)
                )
              ])
            ]
          )
        ],
        1
      ),
      _c("register-valid-dialog", {
        ref: "registerValidDialog",
        attrs: { unitName: _vm.mainForm.unitName, endNumber: _vm.endNumber }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }